export default {
  leastDrawn: 'Najmanje izvučeno',
  mostDrawn: 'Najviše izvučeno',
  leastPlayed: 'Najmanje odigrano',
  mostPlayed: 'Najviše odigrano',
  drawFrom: 'Izvlačenje od',
  h: 'h', // short hours
  startingIn: 'Počinje za',
  waitingForDrawnFrom: 'Čekanje na izvlačenje od',
  displayingDrawFrom: 'Prikaz izvlačenja od',
  lotteryDraw: 'Izvlačenje brojeva',
  eventId: 'Broj runde',
  bettingShortcuts: 'Prečice za opklade',
  draw: 'Izvlačenje',
  next5Events: 'Sledećih {value} izvlačenja',
  events: 'Runde',
  schedule: 'Raspored',
  next: 'Sledeće',
  event: 'Runda',
  later: 'Kasnije',
  statistics: 'Statistika',
  lastnRounds: 'Poslednjih {value} rundi',
  bettingOffer: 'Ponuda za klađenje',
  notDisplayed: 'Nema prikaz',
  latestResults: 'Poslednji rezultati',
  drawnAt: 'Izvučeno u {value}',
  additionalBetting: 'Specijalno klađenje',
  drawSumEvenOdd: 'Ukupan zbir par/nepar',
  drawSumTotal: 'Ukupan zbir',
  drawSumRange: 'Zbir izvlačenja raspon',
  waitingForDrawNo: 'Čekanje na izvlačenje broj {value}',
  nextDraw: 'Sledeće izvlačenje',
  waitingForDraw: 'Čekanje na izvlačenje',
  drawCompleted: 'Izvlačenje završeno',
  drawDisplayTemporarilyNotAvailable: 'Prikaz izvlačenja trenutno nedostupan',
  connectionError: 'Greška u konekciji!',
  reconnecting: 'Ponovno povezivanje...',
  number: 'Broj',
  headerNumberDescriptionUp: 'Broj',
  headerNumberDescriptionDown: 'Runde',
};
