export default {
  leastDrawn: 'CELE MAI PUȚIN EXTRASE',
  mostDrawn: 'CELE MAI EXTRASE',
  leastPlayed: 'CELE MAI PUȚIN JUCATE',
  mostPlayed: 'CELE MAI JUCATE',
  drawFrom: 'Extragerea de la',
  h: 'h', // short hours
  startingIn: 'Începe la',
  waitingForDrawnFrom: 'Urmează extragerea de la',
  displayingDrawFrom: 'Este afișat evenimentul de la',
  lotteryDraw: 'Extragere',
  eventId: 'ID eveniment',
  bettingShortcuts: 'Controale rapide pentru pariuri',
  draw: 'Extragere',
  next5Events: 'Urmatoarele {value}',
  events: 'Evenimente',
  schedule: 'Program',
  next: 'Următorul',
  event: 'Eveniment',
  later: 'Mai tarziu',
  statistics: 'Statistici',
  lastnRounds: 'Ultimele {value} runde',
  bettingOffer: 'Oferta de pariuri',
  notDisplayed: 'Nu se afișează',
  latestResults: 'Ultimele rezultate',
  drawnAt: 'Finalizat în {value}',
  additionalBetting: 'Pariuri suplimentare',
  drawSumEvenOdd: 'Suma numerelor extrase Par/Impar',
  drawSumTotal: 'Suma totală a numerelor extrase',
  drawSumRange: 'Intervalul sumei numerelor extrase',
  waitingForDrawNo: 'Se asteapta numerele extragerii {value}',
  nextDraw: 'Următoarea extragere',
  waitingForDraw: 'Așteptând extragerea',
  drawCompleted: 'Extragere finalizată',
  drawDisplayTemporarilyNotAvailable: 'Afișarea extragerilor este temporar indisponibilă',
  connectionError: 'Eroare de conexiune!',
  reconnecting: 'Reconectare...',
  number: 'Număr',
  headerNumberDescriptionUp: 'Număr',
  headerNumberDescriptionDown: 'Eveniment',
};
